.pendo-resource-center-badge-notification-bubble {
  cursor: pointer !important;
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 5px !important;
  left: -4px !important;
}

.pendo-notification-bubble-unread-count {
  font-size: 75% !important;
  right: -4px !important;
}

._pendo-resource-center-close-button, ._pendo-resource-center-left-caret, ._pendo-resource-center-right-caret {
  cursor: pointer !important;
}
/*# sourceMappingURL=index.818275b1.css.map */
